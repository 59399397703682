<template>
  <div class="inline-flex items-center mb-3 cursor-pointer" @click="back">
    <icon-back />
    <span class="ml-2 text-xs text-invest-purple">Back</span>
  </div>
  <div class="text-2xl font-bold text-invest-primary">Payment method</div>
  <p class="mt-3 mb-5 text-xs text-invest-dark">
    How would you love to fund your investement?
  </p>

  <div
    v-for="(m, i) in methods"
    :key="i"
    class="payment-method"
    :class="method === m.type ? 'border-invest-purple' : 'border-invest-gray'"
    @click="selectMethod(m.type)"
  >
    <div class="inline-flex items-center">
      <div
        class="flex items-center justify-center w-6 h-6 rounded-full"
        :class="method === m.type ? 'bg-invest-purple' : 'border-1 bg-gray-100'"
      >
        <ion-icon
          v-if="method === m.type"
          name="checkmark-outline"
          class="text-gray-100"
        ></ion-icon>
      </div>
      <div class="flex flex-col ml-4">
        <h2 class="mt-1 text-xs font-semibold text-invest-darkblue">
          {{ m.title }}
        </h2>
        <p class="mt-1 mb-1 text-10 text-invest-darkblue text-opacity-80">
          {{ m.subtitle }}
        </p>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-1 mt-36 place-content-end">
    <button
      type="button"
      class="btn btn-primary"
      :disabled="!method || loading"
      @click="makePayment"
    >
      <loader v-if="loading" />
      <span v-else
        >Continue - Pay {{ $filters.formatAmount(investment.amount) }}</span
      >
    </button>
  </div>

  <Korapay
    ref="korapay"
    :amount="investment.amount"
    @confirmTransfer="confirmTransfer"
  />
</template>
<script lang="ts">
import {
  defineComponent,
  SetupContext,
  reactive,
  toRefs,
  ref,
  defineAsyncComponent,
} from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Korapay: defineAsyncComponent(
      () => import('@/components/payments/Korapay'),
    ),
  },
  emits: [
    'changeTab',
    'navigateToSelectTenure',
    'navigateToInvestmentDetails',
    'createInvestment',
  ],
  setup: (_props, { emit }: SetupContext) => {
    const korapay = ref<any>(null);

    const data = reactive({
      methods: [
        // {
        //   type: 'debit-card',
        //   title: 'Bank with Debit Card',
        //   subtitle: 'Use a bank-issued debit card.',
        // },
        {
          type: 'bank-transfer',
          title: 'Pay with Bank Transfer',
          subtitle: 'From any bank account in Nigeria.',
        },
      ],
      method: null as any,
    });

    const back = (): void => emit('changeTab', 'investment-details');

    const selectMethod = (method: any): void => (data.method = method);

    const makePayment = (): any => {
      switch (data.method) {
        case 'bank-transfer':
          return korapay.value.payWithKorapay();
        default:
      }
    };

    const confirmTransfer = ({
      status,
      reference,
    }: {
      status: string;
      reference: string;
    }) => {
      if (status === 'success') {
        emit('createInvestment', { reference, provider: 'korapay' });
      }
    };

    return {
      back,
      ...toRefs(data),
      selectMethod,
      korapay,
      makePayment,
      confirmTransfer,
    };
  },
});
</script>
