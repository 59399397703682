
import {
  defineComponent,
  SetupContext,
  reactive,
  toRefs,
  ref,
  defineAsyncComponent,
} from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Korapay: defineAsyncComponent(
      () => import('@/components/payments/Korapay'),
    ),
  },
  emits: [
    'changeTab',
    'navigateToSelectTenure',
    'navigateToInvestmentDetails',
    'createInvestment',
  ],
  setup: (_props, { emit }: SetupContext) => {
    const korapay = ref<any>(null);

    const data = reactive({
      methods: [
        // {
        //   type: 'debit-card',
        //   title: 'Bank with Debit Card',
        //   subtitle: 'Use a bank-issued debit card.',
        // },
        {
          type: 'bank-transfer',
          title: 'Pay with Bank Transfer',
          subtitle: 'From any bank account in Nigeria.',
        },
      ],
      method: null as any,
    });

    const back = (): void => emit('changeTab', 'investment-details');

    const selectMethod = (method: any): void => (data.method = method);

    const makePayment = (): any => {
      switch (data.method) {
        case 'bank-transfer':
          return korapay.value.payWithKorapay();
        default:
      }
    };

    const confirmTransfer = ({
      status,
      reference,
    }: {
      status: string;
      reference: string;
    }) => {
      if (status === 'success') {
        emit('createInvestment', { reference, provider: 'korapay' });
      }
    };

    return {
      back,
      ...toRefs(data),
      selectMethod,
      korapay,
      makePayment,
      confirmTransfer,
    };
  },
});
